<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-large
      rounded="lg"
    >
    <b-row class="auth-inner m-0">

      <!-- #region left side-->
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="12" md="12" lg="12" style="max-width:600px" class="px-xl-2 mx-auto">
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
            style="font-size: 24px; font-weight: 600; margin-bottom:0px !important"
          >
          <div style="margin-bottom:30px; margin-top:0px">
          <protex-logo style="width: 160px !important; height: auto;" />
          </div>
            Create your account
          </b-card-title>
          <b-card-text class="mb-2" style="font-size: 12px;
                        color: rgba(0,0,0,0.2);
                        font-weight:500;">
            You must be approved to register* 
          </b-card-text>

          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="AwsSignUp">
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      Work Email
                    </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="username"
                    @input="(val) => (username = username.toLowerCase())"
                    style="height:58px; font-size:14px;  box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;"
                    name="register-email"
                    :state="errors.length > 0 && signUpPressed ? false : null"
                    placeholder="Email username"
                  />
                  <small v-if="signUpPressed" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row>
                  <b-col sm="6" md="6" lg="6">
              <b-form-group>
                <div
                  class="d-flex justify-content-between"
                  style="padding-top: 0px"
                >
                  <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      Password
                    </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    style="border-radius:6px; box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;"

                    :class="errors.length > 0 && signUpPressed || signUpPressed && (password != confirmPassword) ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      style="height:58px; font-size:14px;"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 && signUpPressed || signUpPressed && (password != confirmPassword) ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="signUpPressed && errors[0]" class="text-danger">Password must be at least 8 characters</small>
                  <small v-if="(password != confirmPassword) && signUpPressed && !errors[0]" class="text-danger">Passwords must match</small>
                </validation-provider>

              </b-form-group>
              </b-col>

              <b-col sm="6" md="6" lg="6">
              <b-form-group>
                <div
                  class="d-flex justify-content-between"
                  style="padding-top: 0px"
                >
                  <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      Confirm Password
                    </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    style="border-radius:6px; box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;"

                    :class="errors.length > 0 && signUpPressed || signUpPressed && (password != confirmPassword) ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="confirmPassword"
                      style="height:58px; font-size:14px;"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 && signUpPressed || signUpPressed && (password != confirmPassword) ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0] && signUpPressed" class="text-danger">Password must be at least 8 characters</small>
                  <small v-if="(password != confirmPassword) && signUpPressed && !errors[0]" class="text-danger">Passwords must match</small>
                  
                </validation-provider>
              </b-form-group>
              </b-col>

              </b-row>
              <!-- Phone Number -->
              <div
                class="d-flex justify-content-between"
                style="padding-top: 0px"
              >
                <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      Phone Number
                    </label>
              </div>
              <!--
                * Using 3 different versions variables for phone-number
                * phoneNumber -> this is for v-modal to display the phone number
                * RawPhoneNumber -> this is for getting the phone number Object from VuePhoneNumberInput comopnent
                * e164PhoneNumber -> this is for getting the phone number in E.164 format on Update 
               -->
              <b-form-group>
                <VuePhoneNumberInput
                  v-model="phoneNumber"
                  :default-country-code="countryCode"
                  id="phone-input"
                  style="margin-bottom:16px; box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;"
                  :required="true"
                  :clearable="true"
                  @update="RawPhoneNumber = $event"
                />
              </b-form-group>

              <!-- End of Phone Number -->
              <b-form-group>
                <b-card-text class="" style="font-size: 14px;
                        color: #7A8B99;
                        font-weight:500;">
                  <span
                    >By signing up, you agree to our
                    <!-- <b-link href="https://www.protex.ai/privacy-policy">
                      <span style="color: #a2acb5" id="terms-of-service"
                        ><u>Terms of Service</u></span
                      >
                    </b-link>
                    and -->
                    <b-link href="https://www.protex.ai/privacy">
                      <span style="color: #a2acb5" id="privacy-policy"
                        ><u>Privacy Policy</u></span
                      > </b-link
                    >
                  </span>
                </b-card-text>
              </b-form-group>
              <b-button
                variant="primary"
                block
                type="submit"
                style="margin-top:20px; color: white !important; font-size: 18px; font-weight:500; height:56px;"

              >
                Sign up with work email
              </b-button>
            </b-form>
          </validation-observer>

          <p style="margin-top: 16px; font-size: 16px; color: #797F93;">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'auth-login' }" style="color: #a2acb5">
              <span id="sign-in" style="color: #00A0F3; text-decoration: none !important">Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- #endregion -->
      <!-- #region right side-->
      <b-col
          v-if="!this.hideRightCol"
          lg="7"
          style="
            padding: 0px;
            background-repeat: no-repeat;
            background-size: cover;
          "
          :style="{ backgroundImage: 'url(' + bgImg + ')' }"
        >
          <img :src="bgImg" style="width: 100%; visibility:hidden" />
        </b-col>
      <!-- #endregion -->
    </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */

//#region imports
// General
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ProtexLogo from "@core/layouts/components/Logo.vue";
import { Auth } from "aws-amplify";
import { loggedOnce } from "@/vue-apollo";
import {
  getHomeRouteForLoggedInUser,
  getUserRole,
  getUserSiteId,
  getUserId,
  getUserName,
  getUserProfileImage
} from "@/auth/utils";
import VuePhoneNumberInput from "vue-phone-number-input";
import GET_USER_INFO from "@/graphql/login/query/GetUserData.gql";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import axios from 'axios';
import { mapActions } from "vuex";
const SIGNUP_API_ENDPOINT = process.env.VUE_APP_AZURE_SIGN_UP;

// Components
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BOverlay
} from "bootstrap-vue";
//#endregion

export default {
  mixins: [togglePasswordVisibility],

  //#region components
  components: {
    VuePhoneNumberInput,
    ProtexLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BOverlay, 
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  //#endregion

  //#region data
  data() {
    return {
      loading: false,
      countryCode: "IE",
      signUpPressed: false,
      RawPhoneNumber: "",
      e164PhoneNumber: "",
      phoneNumber: "",
      hideRightCol: false,
      status: "",
      username: "",
      userEmail: "",
      password: "",
      confirmPassword: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      bgImg: "./bg-registration.png",
      required,
      email,
      err: ""
    };
  },
  //#endregion

  //#region mounted
  mounted() {
    this.$nextTick(() => {
      // axios.post("http://ipinfo.io", function (response) {
      //   console.log(response)
      // })

  fetch("http://ip-api.com/json")
  .then(response => response.json())
  .then(data => this.countryCode = data.countryCode);

      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideRightCol = true;
      } else {
        this.hideRightCol = false;
      }
      window.addEventListener("resize", this.getDimensions);
    });
  },
  //#endregion
  watch: {
    RawPhoneNumber: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.e164PhoneNumber = newValue.e164;
      }
    }
  },
  //#region computed
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  //#endregion

  //#region methods
  methods: {
    ...mapActions({
      fetchUserAndCompanyInfo: "userSettings/fetchUserAndCompanyInfo",
    }),
    async initiateGoogleAuth() {
      console.log("Signing in With Google");
      await Auth.federatedSignIn({ provider: "Google" })
    },
    getDimensions() {
      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideRightCol = true;
      } else {
        this.hideRightCol = false;
      }
    },

    async onSignInWithProvider() {
      try{
        this.loading = true
        console.log("msal", this.$msal)
        let data = await this.$msal.authenticatePopup()
        this.signUpOrSignInWithMicrosoftToken(data.idToken, data.account.username)
      }catch(e){
        this.loading = false
        this.$toast.warning({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Authentication Failed",
            icon: "AlertCircleIcon",
            variant: "warning",
          }
        });
      }
    },

    async signUpOrSignInWithMicrosoftToken(microsoftIdToken, username) {
      try{
        const attributes = {};
        const userName= username.toLowerCase();
        attributes['custom:email'] = userName;
        attributes['custom:msalIdtoken'] = microsoftIdToken;
        let data = {attributes: attributes, email: userName}
        var response = await axios({
            method: 'GET',
            url: SIGNUP_API_ENDPOINT,
            params: data
        })
        //console.log("response", response)
        //sign up creates user
        if(response.status == 200 && response.data.userCreated && !response.data.isExistingUser){
            this.authenticateWithMicrosoftToken(microsoftIdToken, userName)
        }
        //user already existed
        else if(response.status == 200 && !response.data.userCreated && response.data.isExistingUser){
            this.authenticateWithMicrosoftToken(microsoftIdToken, userName)
        }
        //some other error occured
        else{
          this.loading = false
          this.$toast.warning({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Authentication Failed",
              icon: "AlertCircleIcon",
              variant: "warning",
            }
          });
        }
      }catch(e){
        console.log("error", e.code, e)
        this.$toast.warning({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Authentication Failed",
            icon: "AlertCircleIcon",
            variant: "warning",
          }
        });
      }
    },

    async authenticateWithMicrosoftToken(microsoftIdToken, username) {
      try{
        console.log(this.$msal)
        const userName = username
        let cognitoUser = await Auth.signIn(userName)
        // after sign in is started we need to continue with authentication and we sent microsft token
        let user = await Auth.sendCustomChallengeAnswer(cognitoUser, microsoftIdToken);
        //console.log("res is", user)
        loggedOnce.state = true;
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(user.signInUserSession.refreshToken.token)
        );
        localStorage.setItem(
          "JoT",
          JSON.stringify(user.signInUserSession.idToken.jwtToken)
        );
        localStorage.setItem("Role", getUserRole());
        localStorage.setItem("SiteId", getUserSiteId());

        let userData = {
          fullName: getUserName(),
          username: getUserName(),
          avatar: getUserProfileImage(),
          email: userName,
          role: getUserRole(),
          ability: [
            {
              action: "manage",
              subject: "all"
            }
          ],
          accessToken: user.signInUserSession.accessToken.jwtToken,
          refreshToken: user.signInUserSession.refreshToken.token
        };

        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("userId", getUserId());

        this.$ability.update([
          {
            action: "manage",
            subject: "all"
          }
        ]);

        let data = await Auth.currentSession();
        localStorage.setItem("JoT", JSON.stringify(data.idToken.jwtToken));

        await this.fetchUserAndCompanyInfo()
        this.$router
          .replace(getHomeRouteForLoggedInUser("admin"))
          .then(() => {
            this.loading = false;

            this.intro(userData.fullName.split(" ")[0]);
          });
      }catch(e){
        this.loading = false
        console.log("error", e)
        this.$toast.warning({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Authentication Failed",
              icon: "AlertCircleIcon",
              variant: "warning",
            }
        });
      }
    },


    async AwsSignUp() {
      this.signUpPressed = true
      if(!(this.password.length<8)){
      if(this.password === this.confirmPassword){
        try {
          const { user } = await Auth.signUp({
            username: this.username,
            password: this.password,
            attributes: {
              phone_number: this.e164PhoneNumber
            }
          });
          this.$router.replace({
            name: "verify-account",
            params: { username: this.username }
          });
        } catch (error) {
          this.err = error.message;
          let userMessage = "";
          if (error.name === "UserLambdaValidationException") {
            userMessage =
              "You must request access and get approved before you can register.";
          } else if (error.name === "UsernameExistsException") {
            this.$router.replace({
              name: "auth-login",
            });
            userMessage =
              "An account has already been registered with this email address.";
          } else {
            userMessage = error.message;
          }
          this.$toast.warning({
            component: ToastificationContent,
            position: "top-center",
            props: {
              title: "Unable to Register.",
              text: userMessage,
              icon: "AlertCircleIcon",
              variant: "warning",
            }
          });
        }
      }
      else{
        this.$toast.warning({
            component: ToastificationContent,
            position: "top-center",
            props: {
              title: "Passwords don't match!",
              text: "Please ensure that you have re-typed your password correctly",
              icon: "AlertCircleIcon",
              variant: "warning",
            }
          });
      }
      }
      else{
        this.$toast.warning({
            component: ToastificationContent,
            position: "top-center",
            props: {
              title: "Password Error",
              text: "Password must be at least 8 characters long",
              icon: "AlertCircleIcon",
              variant: "warning",
            }
          });
      }
    }
  }
  //#endregion
};
/* eslint-disable global-require */
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style lang="scss" scoped>

.google-sign-in{
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #7A8A99 !important
}

.google-sign-in:hover{
  color: #052D41!important
}
#terms-of-service:hover {
  color: #00a0f3 !important;
}
#privacy-policy:hover {
  color: #00a0f3 !important;
}
#sign-in:hover {
  color: #00a0f3 !important;
}
</style>

<style>

#phone-input > div.select-country-container > div > div.country-selector__toggle > svg{
  margin-top:14px
}
#phone-input > div.select-country-container > div > div.country-selector__country-flag > div{
  margin-top:10px
}
#phone-input > div.select-country-container > div > div.country-selector__toggle > svg{
  margin-top:14px !important
}
.input-tel__input{
  height:58px !important; font-size:14px; 
  cursor: text
}
.country-selector__input{
  height:58px !important; font-size:14px;  
}
#userback_button_container {
  display: none !important;
}

#crisp-chatbox {
  visibility: hidden !important;
}

#crisp-chatbox > div > a > span.cc-7doi.cc-1ada > span > span.cc-15mo {
  visibility: hidden !important;
}
#crisp-chatbox > div > a > span > span > span{
  visibility: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-size: 14px !important;
}
</style>
